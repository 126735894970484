import firebase from 'firebase/app';
import "firebase/firestore";
import "firebase/database";



const firebaseConfig = {
  apiKey: "AIzaSyAPUGdv4VLNociQJt79NmfhLRclBsTqJwA",
  authDomain: "tuvex-6a35d.firebaseapp.com",
  projectId: "tuvex-6a35d",
  storageBucket: "tuvex-6a35d.appspot.com",
  messagingSenderId: "363542316662",
  appId: "1:363542316662:web:175e4627f4704fc3258157",
};

firebase.initializeApp(firebaseConfig);

export const db =firebase.firestore();
export const rt =firebase.database();

