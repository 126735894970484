import { useMemo } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";

export default function TripMap({ position }) {
  const center = useMemo(() => ({ lat: position.lat || 44, lng: position.lon || -80 }), [position]);

  const { isLoaded } = useLoadScript({ googleMapsApiKey: "AIzaSyAkuraMWzko1VHqGH3loedMC7z-mwVP1kU" });

  if (!isLoaded) return <div>Cargando...</div>;

  return (
    <GoogleMap zoom={14} center={center} mapContainerClassName="map-container">
      <MarkerF position={center}></MarkerF>
    </GoogleMap>
  );
}
