import { useState, useEffect } from "react";
import { rt } from "../firebase/config";
import { useParams } from "react-router-dom";
import TripMap from "./TripMap";
import TripInformation from "./TripInformation";
import NotAvailable from "./NotAvailable";
import Loading from "./Loading";

function Root() {
  const [position, setPosition] = useState(undefined);
  const [tripRequest, setTripRequest] = useState(undefined);
  const [isAvailable, setIsAvailable] = useState(true);
  const { tripRequestId } = useParams();

  // Fetch trip request information
  useEffect(() => {
    rt.ref("activeTripRequests")
      .child(tripRequestId)
      .get()
      .then((tripRequestSnapshot) => {
        if (!tripRequestSnapshot.exists()) setIsAvailable(false);
        else setTripRequest(tripRequestSnapshot.val());
      })
      .catch((error) => {
        console.error(error);
      });
  }, [tripRequestId]);

  // Fetch driver information
  useEffect(() => {
    if (!tripRequest) return;

    rt.ref("busyDrivers")
      .child(tripRequest?.driverId)
      .on("value", (driverPositionSnapshot) => {
        if (!driverPositionSnapshot.exists()) return;

        const newPosition = {
          lat: driverPositionSnapshot.val()["l"][0],
          lon: driverPositionSnapshot.val()["l"][1],
        };

        setPosition(newPosition);
      });
  }, [tripRequest]);

  if (!isAvailable) {
    return <NotAvailable />;
  }

  if (!tripRequest || !position) {
    return <Loading />;
  }

  return (
    <div className="Root">
      <TripMap position={position} />
      <TripInformation tripRequest={tripRequest} />
    </div>
  );
}

export default Root;
