export default function TripInformation({ tripRequest }) {
  return (
    <div className="TripInformation">
      <div className="TripInformation__info">
        <div>
          <b>Teléfono:</b>
          <p className="TripInformation__description">
            {tripRequest.driver ? tripRequest.driver.user.phoneNumber : ""}
          </p>
        </div>
        <div>
          <b>Origen:</b>
          <p className="TripInformation__description">{tripRequest.trip ? tripRequest.trip.origin.description : ""}</p>
        </div>
        <div>
          <b>Destino:</b>
          <p className="TripInformation__description">
            {tripRequest.trip ? tripRequest.trip.destination.description : ""}
          </p>
        </div>
      </div>
    </div>
  );
}
